import { Container, Col, Row } from 'react-bootstrap';
import '../css/Header.css';


function Header() {
  return (
    <Container className='menu'>
      <Row>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
          <div className='columna1_menu'>
            <h1 >YYEM CA</h1>
          </div>
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
        <div className='columna2_menu'>
          <h2>No competimos, servimos!</h2>
          
        </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Header;
