import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../css/SocialMedia.css';
import socialMediaData from '../img/socialMedia/socialMediaData';




function SocialMedia() {
    const [ enlace, setEnlace ] = useState('#');

    useEffect(() => {
    window.location.href=enlace;
    setEnlace('#');
    },[enlace])


    return(
        <Container>
            <Row>
                <Col>
                Conoce nuestras Redes Sociales:
                </Col>
            </Row>
            <Row>
                <Col>
                    <img  className='redes_sociales' src={socialMediaData.socialMediaData[0].imagen} alt='a' onClickCapture={() => setEnlace(socialMediaData.socialMediaData[0].url)}/>
                    <img  className='redes_sociales' src={socialMediaData.socialMediaData[1].imagen} alt='b' onClickCapture={() => setEnlace(socialMediaData.socialMediaData[1].url)}/>
                    <img  className='redes_sociales' src={socialMediaData.socialMediaData[2].imagen} alt='c' onClickCapture={() => setEnlace(socialMediaData.socialMediaData[2].url)}/>
                    <img  className='redes_sociales' src={socialMediaData.socialMediaData[3].imagen} alt='d' onClickCapture={() => setEnlace(socialMediaData.socialMediaData[3].url)}/>
                </Col>
                <row>
                    Comunícate con nosotros:
                </row>
                <Col>
                    <img  className='redes_sociales' src={socialMediaData.socialMediaData[4].imagen} alt='e' onClickCapture={() => setEnlace(socialMediaData.socialMediaData[4].url)}/>
                    <img  className='redes_sociales' src={socialMediaData.socialMediaData[5].imagen} alt='f' onClickCapture={() => setEnlace(socialMediaData.socialMediaData[5].url)}/>
                </Col>
            </Row>    
        </Container>
    )
}

export default SocialMedia;