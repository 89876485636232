import { useEffect, useState } from 'react';
import { Container, Col, Row, Card, Modal, Spinner } from 'react-bootstrap';
import '../css/Services.css'
import servicesData from '../img/services/servicesData.js';
import serviceContext from '../img/services/serviceContext.js';



function Services() {
    const [ modalShow, setModalShow ] = useState(false);
    const [ listado, setListado ] = useState(serviceContext);
    const [ goToChat, setGoToChat ] = useState('#');
    
    
    {useEffect(() => {
      
      {Array.from({ length: listado.length }).map((_, idg) => (
      setTimeout(() => {
        setGoToChat(window.location.href=listado[idg].link);
        setGoToChat(window.location.href=goToChat);
      }, 2500)
         
    ))}
    },[listado,goToChat])} 


    function MydModalWithGrid(props) {
 
      return (
        
          <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered size='lg'>
            <Modal.Header className='b'>
          
          <div>
              <Spinner animation="grow" size="sm" />    
              <Spinner animation="grow" size="md" variant='warning'/>
              <Spinner animation="grow" size="sm" />    
              <Spinner animation="grow" size="md" variant='warning'/>
          </div>
          
              {/* renderizado de la Card Title */}
              <div >  
              {Array.from({ length: listado.length }).map((_, idg) => (
                <Modal.Title id="contained-modal-title-vcenter">
          
                  <div className='tituloContext'>
                    
                    {listado[idg].item1}
                    
                  </div>
                </Modal.Title>

            ))}
          

            <div className='textContext'>
              <br/>
              En unos segundos serás redirigido a nuestro chat.
            </div>
              
          </div>              
            </Modal.Header>
            
          </Modal>
        );
      }

    return(
        <Container>
            <Row>
                <Col>
                <h3  className='titulo_portafolio'> Conoce nuestros servicios, haciendo click en las imágenes:</h3>
                </Col>
            </Row>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} xxl={3} className="g-4">
                {Array.from({ length: servicesData.length }).map((_, idx) => (
                    <Col key={idx} className='texto_portafolio'>
                    <Card  className='back1'>
                        <Card.Img variant="top" src={servicesData[idx].imagen} className='imagenes' onClick={() => setModalShow(true) & setListado(servicesData[idx].id_lista)}/>
                        <MydModalWithGrid show={modalShow} onHide={() => setModalShow(false)} />
                        <Card.Body>
                        <Card.Title>
                          {servicesData[idx].titulo}
                        </Card.Title>
                        <Card.Text>
                          {servicesData[idx].descripcion}
                        </Card.Text>
                        </Card.Body>
                        
                    </Card>
                    </Col>
                ))}
            </Row>
            <Row>
                <Col className='espacio'>
            
            Viaja con nosotros y tendras la experiencia de un servicio cómodo y seguro.
            
                </Col>
            </Row> 
        </Container>
        )
}

export default Services;